import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"

import { parseShortcodes } from "../lib/shortcodes"

const AboutUsPage = ({ pageContext, location }) => {
  const aboutUsQueryData = useStaticQuery(ABOUT_US_QUERY)
  const components = parseShortcodes(aboutUsQueryData.seaPage.content)

  return (
    <Layout
      pathContext={pageContext}
      location={location}
      bannerDetails={{
        title: aboutUsQueryData.seaPage.title,
        description: aboutUsQueryData.seaPage.description,
        image:
          aboutUsQueryData.seaPage.featured_image.source_url,
      }}
    >
      <SEO title={"About us"} location={location} yoast={aboutUsQueryData.seaPage.seo} />
      <Content>{components.map((el) => el)}</Content>
    </Layout>
  )
}

export default AboutUsPage

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -2.2rem;
`

// ===============
//      QUERY
// ===============

const ABOUT_US_QUERY = graphql`
  {
    seaPage(slug: { eq: "about-us" }) {
      title
      description
      slug
      content
      featured_image {
        source_url
      }
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
